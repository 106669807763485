<template>
  <div>
    <vs-col vs-w="12" class="pb-5">
      <vs-row vs-type="flex" vs-w="3" vs-sm="3" vs-xs="12">
        <h1 class="primary font-bold" style="color: #555555">Drugbook</h1>
      </vs-row>
    </vs-col>
    <div>
      <h3 class="primary" style="color: #555555">
        {{ clinicDetail.clinicName }}
      </h3>
      <div class="w-full py-5">
        <p>
          Enter the current balance of each drug listed below based on the last
          entry in your current Drugbook. Leave this field empty for any drug
          you do not stock.
        </p>
        <p>
          We have identified the drugs you most commonly used in treatments at
          this clinic
        </p>
      </div>
      <div class="bg-white rounded-lg p-5">
        <div class="flex flex-no-wrap items-center">
          <h4 class="primary pr-5" style="color: #555555">Drugs</h4>
          <vs-input
            icon="icon-search"
            icon-pack="feather"
            placeholder="Search for a drug"
            v-on:input="debounceInput"
            v-model="searchInput"
          />
        </div>
        <div>
          <form>
            <div class="w-full flex flex-wrap">
              <h4 class="mt-5" v-if="drugItems.with.length > 0">
                Drugs in Use
              </h4>
              <div
                class="flex flex-wrap lg:flex-no-wrap w-full justify-between items-center"
                :key="index"
                v-for="(item, index) in drugItems.with"
              >
                <label class="w-full">{{ item.name }}</label>
                <div class="lg:m-5 my-5 flex flex-no-wrap items-center">
                  <vs-input-number
                    :success="
                      !errors.first(item.id) && clinicData[item.id] != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first(item.id) ? true : false"
                    :danger-text="errors.first(item.id)"
                    v-validate="'required|max:150'"
                    @name="item.id"
                    @data-vv-as="item.id"
                    class="w-full"
                    v-model="clinicData[item.id]"
                    type="number"
                  />
                  <label class="w-16">{{
                    item.unit === "ml" || item.unit === "units"
                      ? item.unit
                      : "units"
                  }}</label>
                </div>
              </div>
              <h4 class="mt-5" v-if="drugItems.without.length > 0">
                All Other Drugs
              </h4>
              <div
                class="flex flex-wrap lg:flex-no-wrap w-full justify-between items-center"
                :key="index"
                v-for="(item, index) in drugItems.without"
              >
                <label class="w-full">{{ item.name }}</label>
                <div class="lg:m-5 my-5 flex flex-no-wrap items-center">
                  <vs-input-number
                    :success="
                      !errors.first(item.id) && clinicData[item.id] != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first(item.id) ? true : false"
                    :danger-text="errors.first(item.id)"
                    v-validate="'required|max:150'"
                    @name="item.id"
                    @data-vv-as="item.id"
                    class="w-full"
                    v-model="clinicData[item.id]"
                    type="number"
                  />
                  <label class="w-16">{{
                    item.unit === "ml" || item.unit === "units"
                      ? item.unit
                      : "units"
                  }}</label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="flex flex-no-wrap items-center justify-between w-full">
          <div>
            <vs-button type="flat" v-if="clincIndex > 0" @click="handlePrev">
              <div class="flex flex-no-wrap items-center">
                <feather-icon
                  icon="ArrowLeftIcon"
                  class="mr-2"
                  svgClasses="h-6 w-6"
                />Previous Clinic
              </div>
            </vs-button>
          </div>
          <div class="flex flex-no-wrap items-center justify-between">
            <p class="p-3">
              {{ clincIndex }}/{{ clinicIdList.length }} Clinics Completed
            </p>
            <vs-button
              v-if="clincIndex + 1 < clinicIdList.length"
              @click="handleNext"
              >Next</vs-button
            >
            <vs-button v-else @click="onComplete">Complete</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import complianceComponent from "../../components/org-owner/compliance/complianceList.vue";
import { mapActions } from "vuex";
import jwt_decode from 'jwt-decode';

export default {
  components: {
    complianceComponent,
  },
  inject: ["getRoleRouteName"],
  data: () => ({
    organizationId: "",
    selectedClincsList: [],
    clinicIdList: [],
    drugItems: { with: [], without: {} },
    clinicsDrugStock: {},
    search: null,
    clincIndex: null,
    clinicnum: 1,
    clinicData: {},
    clinicDetail: {},
    fullDrugItems: [],
    searchInput:'',
    token: undefined,
    embedClinicId: undefined,
    userId: "",
  }),
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationClinicsList",
      "fetchOrganizationAllClinics",
    ]),
    ...mapActions("drugbook", [
      "addStockReceived",
      "fetchDrugEntries",
      "fetchClinicsDugs",
    ]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapActions("product", ["fetchProducts"]),
    /*  async getClinicDrugbook() {
       try {
         const res = await this.fetchDrugEntries({
           id: this.clinicIdList[this.clincIndex],
           limit: this.drugItems.length
         });
         let temp = {}
         Promise.all(res.data.data.docs.forEach(item => {
           temp[item.productInfo.name] = item.balance;
         }));
         this.clinicData = temp;
       } catch (error) {
         console.error(error.message);
       }
     }, */
    debounceInput: _.debounce(function () {
      this.search = this.searchInput;
    }, 500),
    async getClinicDetail() {
      try {
        this.$vs.loading();
        const res = await this.fetchClinicsDetail(
          typeof this.$route.query.clinicsId === "string"
            ? this.$route.query.clinicsId
            : this.$route.query.clinicsId[this.$route.query.index]
        );
        this.clinicDetail = res.data.data;
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        console.error(error.message);
      }
    },
    async getProductsList() {
      try {
        this.$vs.loading();
        const res = await this.fetchClinicsDugs({
          clinicId:
            typeof this.$route.query.clinicsId === "string"
              ? this.$route.query.clinicsId
              : this.$route.query.clinicsId[this.$route.query.index],
          search: this.search,
        });

        this.fullDrugItems =
          !this.search || this.search == ""
            ? [...res.data.data.with, ...res.data.data.without]
            : this.fullDrugItems;
        this.drugItems.with = res.data.data.with;
        this.drugItems.without = res.data.data.without;
        if (
          !this.clinicsDrugStock[
            this.$route.query.clinicsId[this.$route.query.index]
          ] &&
          !Object.keys(this.clinicData).length > 0
        ) {
          this.drugItems.with.forEach((item) => {
            this.clinicData[item.id] = 0;
          });
          this.drugItems.without.forEach((item) => {
            this.clinicData[item.id] = 0;
          });
        }
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        console.error(error.message);
      }
    },
    async onComplete() {
      this.$vs.loading()
      try {
        const tempData = _.pullAll(
          this.fullDrugItems.map((item) => {
            if (this.clinicData[item.id] > 0) {
              return {
                clinicId: this.clinicIdList[this.clincIndex],
                entryDate: new Date(),
                productId: item.id,
                amount: this.clinicData[item.id],
                performedBy: this.userId,
              };
            }
            return undefined;
          }),
          [undefined]
        );
        const allNon0 = _.filter([...tempData], item => item.amount !== null && item.amount > 0);
        if(allNon0.length>0){
          this.clinicsDrugStock[this.clinicIdList[this.clincIndex]] = tempData;
          sessionStorage.clear();
          const data = _.pullAll(_.flatten(
            this.clinicIdList.map((id) => this.clinicsDrugStock[id])
          ).map(item=> item.amount > 0 ? item : undefined),
          [undefined]
        );
          const res = await this.addStockReceived({organizationId: this.organizationId, drugEntries: data});
          if (res.data.title === "Success") {
            this.$vs.notify({
              title: "Success",
              text: "Status updated successfully.",
              color: "success",
            });
            setTimeout(() => {
              const embeded = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`)
              if(embeded){
                 if (this.clincIndex + 1 < this.clinicIdList.length) {
                  this.$router.push({
                    path: '/drugbook/setup/clinic',
                    query: {
                      clinicsId: this.clinicIdList,
                      index: this.clincIndex + 1,
                    },
                  });
                  location.reload();
                } else {
                  this.$router.push({
                    path: '/drugbook/entries',
                    query:{
                      token: this.token,
                      clinicId: this.embedClinicId
                    }
                  });
                }
              }
              else{
                if (this.clincIndex + 1 < this.clinicIdList.length) {
                  this.$router.push({
                    name: this.getRoleRouteName("DrugbookTransfer"),
                    query: {
                      clinicsId: this.clinicIdList,
                      index: this.clincIndex + 1,
                    },
                  });
                  location.reload();
                } else {
                  this.$router.push({
                    name: this.getRoleRouteName("DrugbookEntries"),
                  });
                }
              }
            }, 500);
        } else {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update status. Try again!",
            color: "danger",
          });
        }
        }
        else{
          this.$vs.notify({
          title: "Failed",
          text: "Please enter a starting balance for at least one product",
          color: "danger",
        });
        }
      } catch (error) {
        console.error(error.message);
        this.$vs.notify({
          title: "Failed",
           text: "Failed to update status. Try again!",
          color: "danger",
        });
      }
      this.$vs.loading.close()
    },
    handleNext() {
      const data = this.fullDrugItems.map((item) => ({
        clinicId: this.clinicIdList[this.clincIndex],
        entryDate: new Date(),
        productId: item.id,
        amount: this.clinicData[item.id],
        performedBy: this.userId,
      }));
      const allNon0 = _.filter([...data], item => item.amount !== null && item.amount > 0);
      if(allNon0.length>0){
        this.clinicsDrugStock[this.clinicIdList[this.clincIndex]] = data;
        sessionStorage.setItem(
          "clinicsDrugStock",
          JSON.stringify(this.clinicsDrugStock)
        );

        this.$router.push({
          name: this.getRoleRouteName("DrugbookTransfer"),
          query: { clinicsId: this.clinicIdList, index: this.clincIndex + 1 },
        });
        location.reload();
      }
      else{
        this.$vs.notify({
          title: "Failed",
          text: "Please enter a starting balance for at least one product",
          color: "danger",
        });
      }
    },
    handlePrev() {
      const data = this.fullDrugItems.map((item) => ({
        clinicId: this.clinicIdList[this.clincIndex],
        entryDate: new Date(),
        productId: item.id,
        amount: this.clinicData[item.id],
        performedBy: this.userId,
      }));
      this.clinicsDrugStock[this.clinicIdList[this.clincIndex]] = data;
      sessionStorage.setItem(
        "clinicsDrugStock",
        JSON.stringify(this.clinicsDrugStock)
      );

      this.$router.push({
        name: this.getRoleRouteName("DrugbookTransfer"),
        query: { clinicsId: this.clinicIdList, index: this.clincIndex - 1 },
      });
      location.reload();
    },
  },
  async created() {
     const {token, organizationId, clinicId } = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`))||{};
    if(token && token.length>0){
      this.token = token;
      this.embedClinicId = clinicId;
      this.userId = jwt_decode(this.token).id;
    }
    else{
      this.userId = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      )._id;
    }
     if(organizationId && organizationId.length>0){
      this.organizationId = organizationId
    } else if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    this.clinicIdList =
      typeof this.$route.query.clinicsId === "string"
        ? [this.$route.query.clinicsId]
        : this.$route.query.clinicsId;
    this.clincIndex = parseInt(this.$route.query.index);
    this.getClinicDetail();
    this.getProductsList();

    if (sessionStorage.clinicsDrugStock) {
      this.clinicsDrugStock = JSON.parse(sessionStorage.clinicsDrugStock);

      if (this.clinicsDrugStock[this.clinicIdList[parseInt(this.clincIndex)]]) {
        this.clinicsDrugStock[
          this.$route.query.clinicsId[parseInt(this.clincIndex)]
        ].forEach((element) => {
          this.clinicData[element.productId] = element.amount;
        });
      }
    }
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getProductsList();
      }
    },
  },
};
</script>

<style>
.circle {
  top: 10px;
  right: 10px;
}
</style>
